import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"advantages"} />
		<Helmet>
			<title>
				FieldGoals Arena
			</title>
			<meta name={"description"} content={"Ваш головний напрямок для футбольних розваг!"} />
			<meta property={"og:title"} content={"FieldGoals Arena"} />
			<meta property={"og:description"} content={"Ваш головний напрямок для футбольних розваг!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11:45:26.777Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11:45:26.777Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11:45:26.777Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11:45:26.777Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11:45:26.777Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11:45:26.777Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-1.jpg?v=2024-05-31T11:45:26.777Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header1>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header1>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Наші пропозиції
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				У FieldGoals Arena ми пишаємося тим, що пропонуємо комплексний набір послуг, щоб покращити ваш футбольний досвід. Незалежно від того, чи хочете ви провести турнір, потренуватися зі своєю командою чи насолодитися невимушеною грою, наші об’єкти обладнані для задоволення всіх ваших футбольних потреб. Досліджуйте можливості разом з нами та робіть кожен матч надзвичайним!
			</Text>
		</Section>
		<Section padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Послуги оренди поля
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Кілька розмірів полів: Виберіть поля різних розмірів, включаючи повнорозмірні поля та менші поля для молодіжних і тренувальних сесій.{"\n"}
					<br />
					{" "}Повнорозмірні майданчики: ідеально підходять для офіційних матчів і великих заходів, відповідають стандартам FIFA.{"\n"}
					<br />
					{" "}Тренувальні поля: Ідеально підходить для вправ, практичних занять і невеликих ігор.
Удосконалені системи освітлення: грайте будь-коли з нашими передовими освітлювальними рішеннями, які забезпечують видимість і безпеку.{"\n"}
					<br />
					{" "}Світлодіодне освітлення: Енергоефективне та потужне освітлення, яке імітує природне денне світло.{"\n"}
					<br />
					{" "}Регульована інтенсивність: Налаштуйте рівні освітлення відповідно до часу доби або потреб гравця.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-1.jpg?v=2024-05-31T11:45:26.772Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-1.jpg?v=2024-05-31T11%3A45%3A26.772Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-1.jpg?v=2024-05-31T11%3A45%3A26.772Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-1.jpg?v=2024-05-31T11%3A45%3A26.772Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-1.jpg?v=2024-05-31T11%3A45%3A26.772Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-1.jpg?v=2024-05-31T11%3A45%3A26.772Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-1.jpg?v=2024-05-31T11%3A45%3A26.772Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-1.jpg?v=2024-05-31T11%3A45%3A26.772Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-4%20%281%29.jpg?v=2024-05-31T11:45:26.769Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-4%20%281%29.jpg?v=2024-05-31T11%3A45%3A26.769Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-4%20%281%29.jpg?v=2024-05-31T11%3A45%3A26.769Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-4%20%281%29.jpg?v=2024-05-31T11%3A45%3A26.769Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-4%20%281%29.jpg?v=2024-05-31T11%3A45%3A26.769Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-4%20%281%29.jpg?v=2024-05-31T11%3A45%3A26.769Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-4%20%281%29.jpg?v=2024-05-31T11%3A45%3A26.769Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-4%20%281%29.jpg?v=2024-05-31T11%3A45%3A26.769Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Оренда спорядження
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Високоякісне спорядження: Орендуйте все, що вам потрібно, від м’ячів і сіток до конусів і тактичних дощок.{"\n"}
					<br />
					{" "}Матч-м’ячі: схвалені ФІФА футбольні м’ячі, доступні для ігор і турнірів.{"\n"}
					<br />
					{" "}Обладнання для тренувань:  конуси, перешкоди та інше для ефективних тренувань.
				</Text>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Послуги з організації заходів
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Організація турніру: Дозвольте нам проводити та керувати вашими футбольними турнірами під професійним наглядом.{"\n"}
					<br />
					{" "}Планування подій: Комплексне управління подіями, включаючи планування, суддівство та підрахунок очок.{"\n"}
					<br />
					{" "}Варіанти організації харчування:  співпрацюйте з місцевими продавцями, щоб забезпечити їжу та напої під час заходів.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11:45:26.770Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11%3A45%3A26.770Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11%3A45%3A26.770Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11%3A45%3A26.770Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11%3A45%3A26.770Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11%3A45%3A26.770Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11%3A45%3A26.770Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/2-2.jpg?v=2024-05-31T11%3A45%3A26.770Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-2.jpg?v=2024-05-31T11:45:26.774Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-2.jpg?v=2024-05-31T11%3A45%3A26.774Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-2.jpg?v=2024-05-31T11%3A45%3A26.774Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-2.jpg?v=2024-05-31T11%3A45%3A26.774Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-2.jpg?v=2024-05-31T11%3A45%3A26.774Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-2.jpg?v=2024-05-31T11%3A45%3A26.774Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-2.jpg?v=2024-05-31T11%3A45%3A26.774Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a0c98e2e8e00217c4a90/images/1-2.jpg?v=2024-05-31T11%3A45%3A26.774Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Більше причин вибрати FieldGoals Arena
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Наші послуги пропонують гнучкість і зручність, гарантуючи, що ви можете зосередитися на грі, поки ми займаємося логістикою. Від професійних матчів до громадських заходів, FieldGoals Arena обладнана для проведення та покращення будь-яких футбольних подій. Відкрийте для себе весь спектр наших пропозицій, відвідавши нас або зв’язавшись з нашою командою для отримання більш детальної інформації.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Досліджуйте можливості!
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				І це лише початок. На FieldGoals Arena є набагато більше, ніж здається на перший погляд. Відкрийте для себе повний спектр наших пропозицій, зв’язавшись з нами або відвідавши нас особисто. Дозвольте нам допомогти вам зробити вашу наступну футбольну подію вражаючим успіхом!
			</Text>
		</Section>
		<Components.NewFooter>
			<Override slot="link" />
		</Components.NewFooter>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});